class GTM {
    addGTMEvent = (event: string, variables: Object) => {
        const dataLayer = (window as any).dataLayer as (Object[] | undefined);

        if (!dataLayer) {
            return false;
        }

        dataLayer.push(
            {
                ...variables,
                'event': event,
            }
        );

        return true;
    };
}

window["gtm"] = window["gtm"] || new GTM();
