const CHAT_DELAY = 5000;
let hubspotCallout = false;

const openChat = () => {
    window.HubSpotConversations.widget.load();
};

const main = () => {
    // open chat immediately if a conversation is already in progress,
    // otherwise wait for the delay
    const chatInProgressKey = "chatInProgress";

    window.HubSpotConversations.on("conversationStarted", function () {
        sessionStorage.setItem(chatInProgressKey, "true");
    });
    window.HubSpotConversations.on("conversationClosed", function () {
        sessionStorage.removeItem(chatInProgressKey);
    });

    if (sessionStorage.getItem(chatInProgressKey)) {
        openChat();
    } else {
        setTimeout(() => {
            openChat();
        }, CHAT_DELAY);
    }
};

const waitForHubspot = () => {
    // wait for Hubspot to initialise before continuing
    const timer = setInterval(() => {
        if (window.HubSpotConversations) {
            clearTimeout(timer);
            main();
        }
    }, 50);
};

// See app/views/layouts/_head.html.haml for where the meta tag is set
const hubspotIsEnabled = () => {
  const metaTag = document.querySelector('meta[name="hubspot-enabled"]');
  return !!(metaTag && metaTag.content == "true")
}

document.addEventListener('click', function (e) {
    if (hubspotCallout) { return; }

    if (e.target && e.target.classList.contains('leadin-button')) {
        e.preventDefault();
        e.target.href = "#";
        e.target.classList.add('product-quote');
        e.target.click();
        hubspotCallout = true;
    }
});

// The commented lines below need to be run as a precursor to this script.
// They are currently in application.html.haml
// prevent the chat box from opening automatically
// window.hsConversationsSettings = { loadImmediately: false };

if (hubspotIsEnabled()) {
  waitForHubspot();
}
